<template>
    <div id="app">
        <div style="width: 100%; height: 700px;">
            <tstDnd
                :check-answer-each-drag="true"
                :is-shuffle="false"
                :answer-allow-range="50"
                :quest-align="'auto'"
                :idx="1"
                :q="quizOptions.q"
                :a="quizOptions.a"
            />
        </div>
    </div>
</template>

<script>
import tstDnd from '@/components/education/answer/tstDnd'

export default {
    name: "dndTest",
    components: {
        tstDnd
    },
    data () {
        var quizOptions = {
            q: {
                // 정답 아이템
                value: ['/assets/img/answer_3.png'],
                // 정답의 크기
                size: [['15%']],
                // 정답의 위치
                pos: [['30%', '20%']],
                align: 'auto',
                // 분류 (단일 정답 : 'single', 다중 정답 : 'multi', 다중 순서 정렬 정답 : 'order')
                classification: ['single'],
                // 정답
                answer: [0],
                // 실제 정답 영역
                area: [['0%', '0%', '100%', '100%']],
                type: ['image']
            },
            a: {
                // 문제 아이템
                value: ['/assets/img/answer_3.png', 'Come in', 'work', 'school'],
                // 문제의 크기, array size 가 1 일 때 나머지 문제 아이템의 크기도 첫 번째 값과 동일하게 설정
                size: [[100, 40]],
                // 문제의 위치, 정렬 방식이 'auto' 일 때 문제의 높이 값만 적용됨
                pos: [[0, 600]],
                // 문제의 정렬방식 'auto' 또는 'manual'
                align: 'auto',
                type: ['image', 'string', 'string', 'string']
            }
        }
        // var quizOptions = {
        //     q: {
        //         // 정답 아이템
        //         value: ['/assets/img/answer_3.png'],
        //         // 정답의 크기
        //         size: [['15%']],
        //         // 정답의 위치
        //         pos: [['30%', '20%']],
        //         align: 'auto',
        //         // 분류 (단일 정답 : 'single', 다중 정답 : 'multi', 다중 순서 정렬 정답 : 'order')
        //         classification: ['single'],
        //         // 정답
        //         answer: [0],
        //         // 실제 정답 영역
        //         area: [['-150%', '20%', '-10%', '80%']],
        //         type: ['image']
        //     },
        //     a: {
        //         // 문제 아이템
        //         value: ['desk', 'Come in', 'work', 'school', '2', '1', '4', '3', '6', '5'],
        //         // 문제의 크기, array size 가 1 일 때 나머지 문제 아이템의 크기도 첫 번째 값과 동일하게 설정
        //         size: [[100, 40]],
        //         // 문제의 위치, 정렬 방식이 'auto' 일 때 문제의 높이 값만 적용됨
        //         pos: [[0, 600]],
        //         // 문제의 정렬방식 'auto' 또는 'manual'
        //         align: 'auto',
        //         type: ['string', 'string', 'string', 'string', 'string', 'string', 'string', 'string', 'string', 'string']
        //     }
        // }
        return {
            quizOptions
        }
    },
}
</script>

<style scoped>

</style>